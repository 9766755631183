<template>
  <div class="bgFFF paddingLR20 paddingT20 paddingB20">
    <el-form ref="form" :rules="rules" :model="form" label-width="160px" v-show="!isLook">
      <el-form-item :label="$t('searchModule.Configuration_Name')" prop="tenantPaymentName">
        <el-input v-model="form.tenantPaymentName" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="配置类型" prop="configType" v-if="0">
        <el-radio-group v-model="form.configType" @change="selectConfig">
          <el-radio
            v-for="value in tenantPaymentList"
            :label="value.code"
            :disabled="isEdit"
            :key="value.code"
            >{{ value.desc }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="支付商户" prop="tenantId">
        <el-select
          v-model.trim="form.tenantId"
          :disabled="isEdit"
          @change="selectAlipay()"
          filterable
          size="15"
        >
          <el-option
            :label="value.tenantName"
            :value="value.tenantId"
            :key="value.tenantId"
            v-for="value in getconfigList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="嘉联聚合支付"
        v-show="JLPAY_UNIONPayScencesList != undefined && JLPAY_UNIONPayScencesList != ''"
      >
        <el-checkbox-group v-model="JLPAY_UNIONPayScences">
          <el-checkbox
            v-for="value in JLPAY_UNIONPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="嘉联微信小程序支付"
        v-show="JLPAY_WECHATPayScencesList != undefined && JLPAY_WECHATPayScencesList != ''"
      >
        <el-checkbox-group v-model="JLPAY_WECHATPayScences">
          <el-checkbox
            v-for="value in JLPAY_WECHATPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="嘉联支付宝小程序支付"
        v-show="JLPAY_ALIPAYPayScencesList != undefined && JLPAY_ALIPAYPayScencesList != ''"
      >
        <el-checkbox-group v-model="JLPAY_ALIPAYPayScences">
          <el-checkbox
            v-for="value in JLPAY_ALIPAYPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="微信APP"
        v-if="WX_APPPayScencesList != undefined && WX_APPPayScencesList != ''"
      >
        <el-checkbox-group v-model="WX_APPPayScences">
          <el-checkbox
            v-for="value in WX_APPPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="微信小程序"
        v-if="WX_AppletPayScencesList != undefined && WX_AppletPayScencesList != ''"
      >
        <el-checkbox-group v-model="WX_AppletPayScences" @change="selectApplet">
          <el-checkbox
            v-for="value in WX_AppletPayScencesList"
            :label="value.code"
            :disabled="value.desc.indexOf('扫') != -1 && appletDisabled"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="微信公众号"
        v-if="WX_WechatPayScencesList != undefined && WX_WechatPayScencesList != ''"
      >
        <el-checkbox-group v-model="WX_WechatPayScences" @change="selectWechat">
          <el-checkbox
            v-for="value in WX_WechatPayScencesList"
            :disabled="value.desc.indexOf('扫') != -1 && wechatDisabled"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        label="支付宝APP"
        v-if="ZFB_NomalPayScencesList != undefined && ZFB_NomalPayScencesList != ''"
      >
        <el-checkbox-group v-model="ZFB_NomalPayScences">
          <el-checkbox
            v-for="value in ZFB_NomalPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        label="支付宝小程序"
        v-if="ZFB_AppletPayScencesList != undefined && ZFB_AppletPayScencesList != ''"
      >
        <el-checkbox-group v-model="ZFB_AppletPayScences">
          <el-checkbox
            v-for="value in ZFB_AppletPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="支付宝服务窗"
        v-if="ZFB_FuWuChuangPayScencesList != undefined && ZFB_FuWuChuangPayScencesList != ''"
      >
        <el-checkbox-group v-model="ZFB_FuWuChuangPayScences">
          <el-checkbox
            v-for="value in ZFB_FuWuChuangPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="农行无感支付-应用"
        v-if="ABC_AUTOPAYPayScencesList != undefined && ABC_AUTOPAYPayScencesList != ''"
      >
        <el-checkbox-group v-model="ABC_AUTOPAYPayScences">
          <el-checkbox
            v-for="value in ABC_AUTOPAYPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="农行综合收银台-应用"
        v-if="ABC_CHECKSTANDPayScencesList != undefined && ABC_CHECKSTANDPayScencesList != ''"
      >
        <el-checkbox-group v-model="ABC_CHECKSTANDPayScences">
          <el-checkbox
            v-for="value in ABC_CHECKSTANDPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="招商银行"
        v-if="CMB_UNIONPayScences != undefined && CMB_UNIONPayScences != ''"
      >
        <el-checkbox-group v-model="zhaoshangList" @change="selectZhaoshang">
          <el-checkbox v-for="value in CMB_UNIONPayScences" :label="value.code" :key="value.code">{{
            value.desc
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="平台商户" prop="selectValue">
        <el-transfer
          filterable
          :filter-method="filterMethod"
          filter-placeholder="请输入名称"
          :titles="['未选择', '已选择']"
          v-model="selectValue"
          :data="platformList"
        >
        </el-transfer>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ $t("button.preservation") }}</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </el-form-item>
    </el-form>

    <el-form :model="form" label-width="160px" v-show="isLook">
      <el-form-item :label="$t('searchModule.Configuration_Name')">
        {{ form.tenantPaymentName }}
      </el-form-item>
      <el-form-item label="配置类型" v-if="0">
        {{ form.configTypeName }}
      </el-form-item>
      <el-form-item label="支付商户">
        <el-select
          v-model.trim="form.tenantId"
          @change="selectAlipay()"
          :disabled="isLook"
          filterable
          size="15"
        >
          <el-option
            :label="value.tenantName"
            :value="value.tenantId"
            :key="value.tenantId"
            v-for="value in getconfigList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="嘉联聚合支付"
        v-show="JLPAY_UNIONPayScencesList != undefined && JLPAY_UNIONPayScencesList != ''"
      >
        <el-checkbox-group v-model="JLPAY_UNIONPayScences" :disabled="isLook">
          <el-checkbox
            v-for="value in JLPAY_UNIONPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="嘉联微信小程序支付"
        v-show="JLPAY_WECHATPayScencesList != undefined && JLPAY_WECHATPayScencesList != ''"
      >
        <el-checkbox-group v-model="JLPAY_WECHATPayScences" :disabled="isLook">
          <el-checkbox
            v-for="value in JLPAY_WECHATPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="嘉联支付宝小程序支付"
        v-show="JLPAY_ALIPAYPayScencesList != undefined && JLPAY_ALIPAYPayScencesList != ''"
      >
        <el-checkbox-group v-model="JLPAY_ALIPAYPayScences" :disabled="isLook">
          <el-checkbox
            v-for="value in JLPAY_ALIPAYPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="微信APP"
        v-show="WX_APPPayScencesList != undefined && WX_APPPayScencesList != ''"
      >
        <el-checkbox-group v-model="WX_APPPayScences">
          <el-checkbox
            v-for="value in WX_APPPayScencesList"
            :disabled="value.desc.indexOf('微信APP') != -1"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="微信小程序"
        v-show="WX_AppletPayScencesList != undefined && WX_AppletPayScencesList != ''"
      >
        <el-checkbox-group v-model="WX_AppletPayScences" :disabled="isLook">
          <el-checkbox
            v-for="value in WX_AppletPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="微信公众号"
        v-show="WX_WechatPayScencesList != undefined && WX_WechatPayScencesList != ''"
      >
        <el-checkbox-group v-model="WX_WechatPayScences" :disabled="isLook">
          <el-checkbox
            v-for="value in WX_WechatPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        label="支付宝APP"
        v-show="ZFB_NomalPayScencesList != undefined && ZFB_NomalPayScencesList != ''"
      >
        <el-checkbox-group v-model="ZFB_NomalPayScences" :disabled="isLook">
          <el-checkbox
            v-for="value in ZFB_NomalPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="支付宝小程序"
        v-if="ZFB_AppletPayScencesList != undefined && ZFB_AppletPayScencesList != ''"
      >
        <el-checkbox-group v-model="ZFB_AppletPayScences" :disabled="isLook">
          <el-checkbox
            v-for="value in ZFB_AppletPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="支付宝服务窗"
        v-show="ZFB_FuWuChuangPayScencesList != undefined && ZFB_FuWuChuangPayScencesList != ''"
      >
        <el-checkbox-group v-model="ZFB_FuWuChuangPayScences" :disabled="isLook">
          <el-checkbox
            v-for="value in ZFB_FuWuChuangPayScencesList"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="农行无感支付-应用"
        v-if="ABC_AUTOPAYPayScences != undefined && ABC_AUTOPAYPayScences != ''"
      >
        <el-checkbox-group v-model="ABC_AUTOPAYPayScences">
          <el-checkbox
            v-for="value in ABC_AUTOPAYPayScences"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="农行综合收银台-应用"
        v-if="ABC_CHECKSTANDPayScences != undefined && ABC_CHECKSTANDPayScences != ''"
      >
        <el-checkbox-group v-model="ABC_CHECKSTANDPayScences">
          <el-checkbox
            v-for="value in ABC_CHECKSTANDPayScences"
            :label="value.code"
            :key="value.code"
            >{{ value.desc }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="招商银行"
        v-if="CMB_UNIONPayScences != undefined && CMB_UNIONPayScences != ''"
      >
        <el-checkbox-group v-model="zhaoshangList" :disabled="isLook">
          <el-checkbox v-for="value in CMB_UNIONPayScences" :label="value.code" :key="value.code">{{
            value.desc
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!--<el-form-item label="小程序">-->
      <!--<el-checkbox-group v-model="form.type">-->
      <!--<el-checkbox label="公众号" name="type"></el-checkbox>-->
      <!--<el-checkbox label="二维码主扫" name="type"></el-checkbox>-->
      <!--<el-checkbox label="二维码被扫" name="type"></el-checkbox>-->
      <!--</el-checkbox-group>-->
      <!--</el-form-item>-->
      <!--<el-form-item label="APP">-->
      <!--<el-checkbox-group v-model="form.type">-->
      <!--<el-checkbox label="APP" name="type"></el-checkbox>-->
      <!--<el-checkbox label="二维码主扫" name="type"></el-checkbox>-->
      <!--<el-checkbox label="二维码被扫" name="type"></el-checkbox>-->
      <!--</el-checkbox-group>-->
      <!--</el-form-item>-->
      <!--<el-form-item label="服务窗">-->
      <!--<el-checkbox-group v-model="form.type">-->
      <!--<el-checkbox label="服务窗" name="type"></el-checkbox>-->
      <!--</el-checkbox-group>-->
      <!--</el-form-item>-->
      <!--<el-form-item label="通用">-->
      <!--<el-checkbox-group v-model="form.type">-->
      <!--<el-checkbox label="二维码主扫" name="type"></el-checkbox>-->
      <!--<el-checkbox label="二维码被扫" name="type"></el-checkbox>-->
      <!--</el-checkbox-group>-->
      <!--</el-form-item>-->

      <el-form-item label="平台商户" style="pointer-events: none; opacity: 0.5">
        <!--<el-transfer-->
        <!--filterable-->
        <!--:disabled="isLook"-->
        <!--:filter-method="filterMethod"-->
        <!--filter-placeholder="请输入名称"-->
        <!--:titles="['未选择', '已选择']"-->
        <!--v-model="selectValue"-->
        <!--:data="platformList">-->
        <!--</el-transfer>-->
        {{ detailOperation }}
      </el-form-item>
      <el-form-item :label="$t('searchModule.Creation_time')">
        {{ form.createdTime }}
      </el-form-item>
      <el-form-item :label="$t('searchModule.Update_time')">
        {{ form.updatedTime }}
      </el-form-item>
      <el-form-item :label="$t('searchModule.Operator')">
        {{ form.operatorName }}
      </el-form-item>
      <el-form-item>
        <!--<el-button type="primary" @click="onSubmit">{{ $t('button.preservation') }}</el-button>-->
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "edit",
  data() {
    return {
      selectValue: [],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      form: {
        paySceneSet: "",
        name: "",
        tenantId: "",
        region: "",
        configType: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        tenantPaymentName: "",
        createdTime: "",
        updatedTime: "",
        operatorName: "",
      },
      rules: {
        tenantPaymentName: [
          {
            required: true,
            message: "请选择配置名称",
            trigger: "blur",
          },
        ],
        configType: [
          {
            required: true,
            message: "请选择配置类型",
            trigger: "blur",
          },
        ],
        tenantId: [
          {
            required: true,
            message: "请选择支付商户",
            trigger: "blur",
          },
        ],
      },
      isEdit: false,
      isLook: false,
      tenantPaymentList: [],
      getconfigList: [],
      showalipayType: [],
      WX_APPPayScences: [],
      WX_APPPayScencesList: [],
      ZFB_FuWuChuangPayScences: [],
      ZFB_FuWuChuangPayScencesList: [],
      ZFB_NomalPayScences: [],
      ZFB_NomalPayScencesList: [],
      WX_AppletPayScences: [],
      WX_AppletPayScencesList: [],
      WX_WechatPayScences: [],
      WX_WechatPayScencesList: [],
      platformList: [],
      detailOperation: [],
      wechatDisabled: false,
      appletDisabled: false,
      CMB_UNIONPayScences: [],
      zhaoshangList: [],
      JLPAY_WECHATPayScences: [], // 嘉联支付微信公众号小程序支付
      JLPAY_WECHATPayScencesList: [],
      JLPAY_ALIPAYPayScencesList: [],
      JLPAY_ALIPAYPayScences: [],
      JLPAY_UNIONPayScences: [], // 嘉联聚合支付
      JLPAY_UNIONPayScencesList: [],
      ABC_AUTOPAYPayScences: [],
      ABC_AUTOPAYPayScencesList: [],
      ABC_CHECKSTANDPayScences: [],
      ABC_CHECKSTANDPayScencesList: [],
      ZFB_AppletPayScences: [],
      ZFB_AppletPayScencesList: [],
    };
  },
  methods: {
    selectApplet() {
      if (
        this.WX_AppletPayScences.join(",").indexOf(122) != -1 ||
        this.WX_AppletPayScences.join(",").indexOf(121) != -1
      ) {
        this.wechatDisabled = true;
      } else {
        this.wechatDisabled = false;
      }
    },
    selectWechat() {
      if (
        this.WX_WechatPayScences.join(",").indexOf(111) != -1 ||
        this.WX_WechatPayScences.join(",").indexOf(112) != -1
      ) {
        this.appletDisabled = true;
      } else {
        this.appletDisabled = false;
      }
    },
    selectZhaoshang() {
      console.log(this.zhaoshangList);
    },
    /* 平台商户配置相关枚举 */
    gettenantPayment() {
      this.$axios.get("/acb/2.0/tenantPayment/enums").then((res) => {
        if (res.state == 0) {
          this.tenantPaymentList = res.value.configTypes; // 平台
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    /* 支付商户下拉框 */
    getconfigTypeList() {
      if (this.$route.query.state == "add") {
        this.form.tenantId = "";
      }
      // this.form.tenantId = ''
      this.$axios
        .get("/acb/2.0/tenant/noConfigTenantList", {
          data: {
            configType: this.form.configType,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.getconfigList = res.value;
            this.selectAlipay();
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    // 支付商户下拉框选中
    AlipayTypeList() {
      this.WX_APPPayScencesList = [];
      this.ZFB_FuWuChuangPayScencesList = [];
      this.ZFB_NomalPayScencesList = [];
      this.WX_AppletPayScencesList = [];
      this.WX_WechatPayScencesList = [];
      this.JLPAY_WECHATPayScencesList = [];
      this.JLPAY_ALIPAYPayScencesList = [];
      this.JLPAY_UNIONPayScencesList = [];
      this.ABC_AUTOPAYPayScencesList = [];
      this.ABC_CHECKSTANDPayScencesList = [];
      this.ZFB_AppletPayScencesList = [];
      // console.log(this.WX_APPPayScencesList,  'this.WX_APPPayScencesList')
      // console.log(this.ZFB_FuWuChuangPayScencesList,  'this.ZFB_FuWuChuangPayScencesList')
      // console.log(this.ZFB_NomalPayScencesList,  'this.WX_APPPayScencesList')
      // console.log(this.WX_APPPayScencesList,  'this.WX_APPPayScencesList')
      // console.log(this.WX_WechatPayScencesList,  'this.WX_WechatPayScencesList')
      // this.WX_APPPayScences = '';
      // this.ZFB_FuWuChuangPayScences = '';
      // this.ZFB_NomalPayScences = '';
      // this.WX_AppletPayScences = '';
      // this.WX_WechatPayScences = '';
      this.$axios
        .get("/acb/2.0/tenant/detail", {
          data: {
            tenantId: this.form.tenantId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.WX_APPPayScencesList = res.value.attributes.WX_APPPayScences; // APP微信
            this.ZFB_FuWuChuangPayScencesList = res.value.attributes.ZFB_FuWuChuangPayScences; // 服务窗
            this.ZFB_NomalPayScencesList = res.value.attributes.ZFB_NomalPayScences; // 通用
            this.WX_AppletPayScencesList = res.value.attributes.WX_AppletPayScences; // 小程序
            this.WX_WechatPayScencesList = res.value.attributes.WX_WechatPayScences; // 微信公众号
            this.CMB_UNIONPayScences = res.value.attributes.CMB_UNIONPayScences; // 招行银行
            this.JLPAY_WECHATPayScencesList = res.value.attributes.JLPAY_WECHATPayScences;
            this.JLPAY_ALIPAYPayScencesList = res.value.attributes.JLPAY_ALIPAYPayScences;
            this.JLPAY_UNIONPayScencesList = res.value.attributes.JLPAY_UNIONPayScences;
            this.ABC_AUTOPAYPayScencesList = res.value.attributes.ABC_AUTOPAYPayScences;
            this.ABC_CHECKSTANDPayScencesList = res.value.attributes.ABC_CHECKSTANDPayScences;
            this.ZFB_AppletPayScencesList = res.value.attributes.ZFB_AppletPayScences;
            // this.WX_APPPayScences = '';
            // this.ZFB_FuWuChuangPayScences = '';
            // this.ZFB_NomalPayScences = '';
            // this.WX_AppletPayScences = '';
            // this.WX_WechatPayScences = '';
            console.log("CMB_UNIONPayScences--->>", this.CMB_UNIONPayScences);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    // 支付商户下拉框下对应的平台商户
    getplatformList(e) {
      this.platformList = [];
      this.$axios
        .get("/acb/2.0/tenantPayment/noBoundOperationList", {
          data: {
            tenantId: this.form.tenantId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.platformList = [];
            let data = [];
            res.value.forEach((item, index) => {
              data.push({
                label: item.operationName,
                key: item.operationId,
                operationId: item.operationId,
              });
              this.platformList = data;
            });
            return data;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    detailShowList() {
      this.$axios
        .get("/acb/2.0/tenantPayment/detail", {
          data: {
            tenantId: this.$route.query.tenantId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          } else {
            console.log(res.value.attributes);
            this.form = res.value;
            this.selectValue = res.value.operationIdSet;
            this.form.selectValue = res.value.operationList;
            this.form.tenantId = res.value.tenantId;
            var Operation = [];
            for (var i = 0; i < res.value.operationList.length; i++) {
              Operation.push(res.value.operationList[i].operationName);
            }
            this.detailOperation = Operation.join(",");
            this.WX_APPPayScences = res.value.attributes.WX_APPPayScences
              ? res.value.attributes.WX_APPPayScences
              : [];
            this.ZFB_FuWuChuangPayScences = res.value.attributes.ZFB_FuWuChuangPayScences
              ? res.value.attributes.ZFB_FuWuChuangPayScences
              : [];
            this.ZFB_NomalPayScences = res.value.attributes.ZFB_NomalPayScences
              ? res.value.attributes.ZFB_NomalPayScences
              : [];
            this.WX_AppletPayScences = res.value.attributes.WX_AppletPayScences
              ? res.value.attributes.WX_AppletPayScences
              : [];
            this.WX_WechatPayScences = res.value.attributes.WX_WechatPayScences
              ? res.value.attributes.WX_WechatPayScences
              : [];
            this.zhaoshangList = res.value.attributes.CMB_UNIONPayScences
              ? res.value.attributes.CMB_UNIONPayScences
              : [];
            this.JLPAY_ALIPAYPayScences = res.value.attributes.JLPAY_ALIPAYPayScences;
            this.JLPAY_WECHATPayScences = res.value.attributes.JLPAY_WECHATPayScences;
            this.JLPAY_UNIONPayScences = res.value.attributes.JLPAY_UNIONPayScences;
            this.ABC_AUTOPAYPayScences = res.value.attributes.ABC_AUTOPAYPayScences;
            this.ABC_CHECKSTANDPayScences = res.value.attributes.ABC_CHECKSTANDPayScences;
            this.ZFB_AppletPayScences = res.value.attributes.ZFB_AppletPayScences;
            // console.log(this.WX_WechatPayScences);
            this.selectConfig();
          }
        });
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.selectValue == "") {
            this.$alert("商户平台不能为空", this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
            return;
          }
          if (
            this.WX_APPPayScences == "" &&
            this.ZFB_FuWuChuangPayScences == "" &&
            this.ZFB_NomalPayScences == "" &&
            this.WX_AppletPayScences == "" &&
            this.WX_WechatPayScences == "" &&
            this.JLPAY_UNIONPayScences == "" &&
            this.JLPAY_WECHATPayScences == "" &&
            this.JLPAY_ALIPAYPayScences == "" &&
            this.zhaoshangList == "" &&
            this.ABC_AUTOPAYPayScences == "" &&
            this.ABC_CHECKSTANDPayScences == "" &&
            this.ZFB_AppletPayScences == ""
          ) {
            this.$alert("至少选择一种支付方式", this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
            return;
          }
          let url = this.isEdit ? "/acb/2.0/tenantPayment/update" : "/acb/2.0/tenantPayment/add";
          this.$axios
            .post(url, {
              data: {
                tenantPaymentName: this.form.tenantPaymentName,
                configType: this.form.configType,
                tenantId: this.form.tenantId,
                WX_APPPayScences: this.WX_APPPayScences,
                WX_WechatPayScences: this.WX_WechatPayScences,
                WX_AppletPayScences: this.WX_AppletPayScences,
                ZFB_NomalPayScences: this.ZFB_NomalPayScences,
                ZFB_FuWuChuangPayScences: this.ZFB_FuWuChuangPayScences,
                paySceneList: this.zhaoshangList,
                JLPAY_UNIONPayScences: this.JLPAY_UNIONPayScences,
                JLPAY_WECHATPayScences: this.JLPAY_WECHATPayScences,
                JLPAY_ALIPAYPayScences: this.JLPAY_ALIPAYPayScences,
                ABC_AUTOPAYPayScences: this.ABC_AUTOPAYPayScences,
                ABC_CHECKSTANDPayScences: this.ABC_CHECKSTANDPayScences,
                ZFB_AppletPayScences: this.ZFB_AppletPayScences,
                // WX_APPPayScences: this.form.WX_APPPayScences ? this.form.WX_APPPayScences.json(',') : '',
                // WX_WechatPayScences: this.form.WX_WechatPayScences ? this.form.WX_WechatPayScences.json(',') : '',
                // WX_AppletPayScences: this.form.WX_AppletPayScences ? this.form.WX_AppletPayScences.json(',') : '',
                // ZFB_NomalPayScences: this.form.ZFB_NomalPayScences ? this.form.ZFB_NomalPayScences.json(',') : '',
                // ZFB_FuWuChuangPayScences: this.form.ZFB_FuWuChuangPayScences ? this.form.ZFB_FuWuChuangPayScences.json(',') : '',
                operationIdList: this.selectValue.join(","),
              },
            })
            .then((res) => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                });
              } else {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                }).then(() => {
                  this.$router.go(-1);
                });
              }
            });
        }
      });
    },
    selectAlipay() {
      this.AlipayTypeList();
      this.getplatformList();
    },
    selectConfig() {
      this.appletDisabled = false;
      this.wechatDisabled = false;
      this.getconfigTypeList();
      if (this.$route.query.state == "add") {
        this.WX_APPPayScences = [];
        this.ZFB_FuWuChuangPayScences = [];
        this.ZFB_NomalPayScences = [];
        this.WX_AppletPayScences = [];
        this.WX_WechatPayScences = [];
        this.zhaoshangList = [];
        this.ABC_AUTOPAYPayScences = [];
        this.ABC_CHECKSTANDPayScences = [];
        this.ZFB_AppletPayScences = [];
      }
    },
  },
  mounted() {
    this.getconfigTypeList();
    if (this.$route.query.state == "edit") {
      this.isEdit = true;
      this.detailShowList();
    }
    if (this.$route.query.state == "detail") {
      this.isLook = true;
      this.detailShowList();
    }
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
.el-input
  width 20%
</style>
